import { Color } from "../GlobalStyles";

// A function to replace special characters in a string for safe transmission or storage
export const send_specialCharReplace = text => {
  console.log("text:", text);
  let replacedText;
  if (![null, undefined].includes(text)) {
    if (typeof text === "string") {
      replacedText = text
        .replace(/"/g, "%%") // Replace all double quotes (") with the string "%%"
        .replace(/'/g, "@@") // Replace all single quotes (') with the string "@@"
        .replace(/\n/g, "||n") // Replace all newlines (\n) with the string "||n"
        .replace(/\//g, "[?]") // Replace all forward slashes (/) with the string "[?]"
        .replace(/\\/g, "||"); // Replace all backslashes (\) with the string "||"
    } else if (Array.isArray(text)) {
      replacedText = text.map(each => {
        return (
          each
            ?.replace(/"/g, "%%") // Replace all double quotes (") with the string "%%"
            .replace(/'/g, "@@") // Replace all single quotes (') with the string "@@"
            .replace(/\n/g, "||n") // Replace all newlines (\n) with the string "||n"
            .replace(/\//g, "[?]") // Replace all forward slashes (/) with the string "[?]"
            .replace(/\\/g, "||")
        ); // Replace all backslashes (\) with the string "||"
      });
    } else if (typeof text === "number") return text;
    console.log("replacedText:", replacedText);
    // Return the modified string or array of strings
    return replacedText;
  } else {
    return text;
  }
};
// A function to replace the special characters back to their original form
export const get_specialCharReplace = text => {
  console.log("text:", text);
  if (![null, undefined].includes(text)) {
    let replacedText;
    if (typeof text === "string") {
      replacedText = text
        ?.replace(/%%/g, '"') // Replace all occurrences of the string "%%" with double quotes (")
        .replace(/@@/g, "'") // Replace all occurrences of the string "@@" with single quotes (')
        .replace(/\|\|n/g, "\n") // Replace all occurrences of the string "||n" with a newline (\n)
        .replace(/\[\?\]/g, "/") // Replace all occurrences of the string "[?]" with a forward slash (/)
        .replace(/\|\|/g, "\\"); // Replace all occurrences of the string "||" with a backslash (\)
    } else if (Array.isArray(text)) {
      replacedText = text.map(each => {
        return each
          ?.replace(/%%/g, '"') // Replace all occurrences of the string "%%" with double quotes (")
          .replace(/@@/g, "'") // Replace all occurrences of the string "@@" with single quotes (')
          .replace(/\|\|n/g, "\n") // Replace all occurrences of the string "||n" with a newline (\n)
          .replace(/\[\?\]/g, "/") // Replace all occurrences of the string "[?]" with a forward slash (/)
          .replace(/\|\|/g, "\\"); // Replace all occurrences of the string "||" with a backslash (\)
      });
    } else return text;
    console.log("replacedText:", replacedText);
    // Return the modified string or array of strings
    return replacedText;
  } else {
    return text;
  }
};

export function calculateEpochTimeInLocalTime(selectedDate) {
  if (selectedDate) {
    console.log("selectedDate", selectedDate);
    const date = new Date(selectedDate);
    const selectedTime = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return selectedTime;
  } else {
    return (<span style={{ color: Color.neutralLightGrey }}>N/A</span>)
  }

}

export function extractDateFromDateString(dateString) {
  if (dateString !== null) {
    console.log("date passed", dateString);
    const inputDate = new Date(dateString);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    console.log("what's returning", year, month, day);
    return `${year}-${month}-${day}`;
  } else {
    console.log("date passed null", dateString);
    return (<span style={{ color: Color.neutralLightGrey }}>Date not selected</span>)
  }

}



export function convertLocalDateTimeToEpoch(localDate, localTime) {
  // Concatenate the date and time strings into a single string
  const dateTimeString = localDate + " " + localTime;
  // Create a Date object from the concatenated string
  const dateTime = new Date(dateTimeString);
  // Get the epoch time in milliseconds
  const epochTime = dateTime.getTime();
  return epochTime;
}
export const  getEpochFor1030AMIST =(flag)=> {
  // Create a new Date object from the timestamp
  const date = new Date(flag);

  console.log("date:", date, flag, typeof flag);

  // Convert to IST (UTC + 5:30)
  let istDate = new Date(date.getTime() + (5.5 * 60 * 60 * 1000));

  // Extract year, month, and date components in IST
  let year = istDate.getUTCFullYear();
  let month = istDate.getUTCMonth(); // Note: month is zero-indexed
  let day = istDate.getUTCDate();

  // Create a new Date object for 10:30 AM IST (which is 05:00 AM UTC)
  let ist1030AM = new Date(Date.UTC(year, month, day, 5, 0)); // No need to subtract anything

  // Get the epoch timestamp in milliseconds for 10:30 AM IST
  let epochAt1030AM = ist1030AM.getTime();
  console.log("epochAt1030AM:", epochAt1030AM);

  return epochAt1030AM;
}

export const epochToMidnight =(flag) =>{
  const date = new Date((flag));
            console.log("date", date, flag, typeof flag)
            // Convert to IST (UTC + 5:30)
            let istDate = new Date(date.getTime() + (5.5 * 60 * 60 * 1000));

            // Extract year, month, and date components in IST
            let year = istDate.getUTCFullYear();
            let month = istDate.getUTCMonth(); // Note: month is zero-indexed
            let day = istDate.getUTCDate();

            // Create a new Date object for 12:00 AM IST (midnight)
            let midnightIST = new Date(Date.UTC(year, month, day) - (5.5 * 60 * 60 * 1000));

            // Get the epoch timestamp in milliseconds for 12:00 AM IST
            let epochAtMidnight = midnightIST.getTime();
            console.log("epochAtMidnight:", epochAtMidnight);
            return epochAtMidnight;
}
export const formatConvertermonthfirst = (date, includeTime = false, day = false, includeYear = true) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const d = new Date(date);

  let formattedDate = "";
  if (day) {
    formattedDate = dayNames[d.getDay()] + ", ";
  }
  formattedDate += d.getDate() + " " + monthNames[d.getMonth()];

  if (includeYear) {
    formattedDate += " " + d.getFullYear();
  }

  if (includeTime) {
    const hours = d.getHours();
    const minutes = d.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    const formattedTime = `${(hours % 12) || 12}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
    formattedDate += `, ${formattedTime}`;
  }

  return formattedDate;
}

export function convertEpochToNormalTime(epochTime) {
  // Create a new Date object with the epoch time in milliseconds
  const date = new Date(epochTime);

  // Define the days of the week and months
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Get the day of the week, month, and time from the date object
  const dayOfWeek = daysOfWeek[date.getDay()];
  const month = months[date.getMonth()];
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine if it's AM or PM
  const amOrPm = hours < 12 ? "AM" : "PM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Construct the formatted date string
  const formattedDate = `${formattedHours}:${minutes.toString().padStart(2, "0")} ${amOrPm}`;

  // Return the formatted date
  return formattedDate;
}

export function epochToDate(epochTime) {
  const date = new Date(epochTime ); // Convert epoch to milliseconds
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}