import { ArrowBackIosNew, Attachment, Search } from '@mui/icons-material';
import { Avatar, Box, Button, Divider, InputAdornment, Modal, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Color, DivCenter, OfflineSessionsContainedPrimaryButton, styles } from '../../../../GlobalStyles'
import SideNavigationBar from '../../../reusables/SideNavigationBar/SideNavigationBar';
import { Typography } from 'antd';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridRowsProp, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import Service from '../../../../services/httpService';
import styled from 'styled-components';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CCService from '../../../../services/httpServiceWithoutSuperAdmin';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { SmallPageLoader } from '../../../reusables/Loader/Loader';
import PdfViewer from './PDFViewer';
import Papa from "papaparse";
import { saveAs } from "file-saver";
function PocketExpenses() {

    const services = new CCService();
    const navigate = useNavigate();
    const apiRef = useGridApiRef();
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [query, setQuery] = useState("");
    const [data, setData] = useState([]);
    const [viewAttachment, setViewAttachment] = useState({
        open: false,
        onClose: () => setViewAttachment((prev) => ({ ...prev, open: false })),
        expenses: []
    })
    const process_id = searchParams.get("process_id");

    const getOOPE = async () => {
        setLoading(true);
        try {
            const res = await services.get("/os/superadmin/oope?process_id=" + process_id);
            console.log("getoope expensessss:", res.data)
            const groupedData = res.data.reduce((acc, item) => {
                if (!acc[item.mentor_id]) {
                    acc[item.mentor_id] = [];
                }
                acc[item.mentor_id].push(item);
                return acc;
            }, {});
            console.log("getoope gd:", groupedData);
            setData(groupedData);

            setOOPE(
                Object.keys(groupedData).map((item) => {
                    return (
                        {
                            mentor: {
                                icon: groupedData[item][0].mentor_photo, name: groupedData[item][0].mentor_name, mentor_id: groupedData[item][0].mentor_id
                            },
                            Expenses: groupedData[item].map((expense, index) => {
                                return ({
                                    expense_type_name: expense.expense_type_name,
                                    expense_type_id: expense.expense_type_id,
                                    description: expense.description,
                                    attachment: expense.attachment,
                                    price: expense.price,
                                    timestamp: expense.timestamp
                                })
                            }),
                            Attachments: 7,
                            Action: { is_settled: groupedData[item][0].is_settled }
                        }
                    )
                })
            )

            setLoading(false);
        } catch (err) {
            console.log("err in getoope:", err)
        }
    }

    const [OOPE, setOOPE] = useState([
        // { mentor: "Hemant Verma", Expenses: 6, Attachments: 2, Action: "edit" }, { mentor: " Verma Hemant", Expenses: 6, Attachments: 2, Action: "edit" }, { mentor: "Hemant Verma", Expenses: 6, Attachments: 2, Action: "edit" }
    ]);


    const DynamicRow = styled('div')(({ theme }) => ({
        '& .row--dynamicHeight': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            height: 'auto !important',
        },
    }));


    const getEstimatedRowHeight = (params) => {
        // Calculate the height based on the content
        const lineHeight = 32; // Height of each line including padding/margins
        const numberOfLines = 2; // Number of lines of content
        return lineHeight * numberOfLines;
    };

    const handlePutSettled = async (is_settled, mentor_id) => {
        const body = {
            process_id: process_id,
            mentor_id: mentor_id,
            is_settled: is_settled
        }
        try {
            const res = await services.put("/os/superadmin/oope", body);
            console.log("Successss", res);
        } catch (error) {
            console.log("error happended in [put]", error)
        }
    }
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    };


    const downloadCSV = (details) => {

        const temp = OOPE.map((item) => {

            return {
                // ...item,
                mentor_name: item.mentor.name,
                expense_name: item.Expenses.map((item, index) => { return (item.expense_type_name) }),
                attachment: item.Expenses.map((item, index) => { return (item.attachment) }),
                price: item.Expenses.reduce((acc, curr) => acc + curr.price, 0),
                description: item.Expenses.map((item, index) => { return (item.description) }),
                is_settled: item.Expenses.is_settled === 0 ? "false" : "true"
            }
        })
        console.log("temp:", temp,);
        const csvData = Papa.unparse(temp);

        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `Student_Summary.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const imgDownload = (url, filename, mimeType) => {
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then((blob) => {
                const currentDate = new Date();
                const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}`;
                const fullFilename = `${filename}_${formattedDate}`;

                // Ensure the blob has the correct MIME type for the image
                const imageBlob = new Blob([blob], { type: mimeType });
                saveAs(imageBlob, fullFilename);
            })
            .catch((error) => console.error('Error downloading file:', error));
    };

    const downloadmultiplefiles = () => {
        try {
            const allAttachment = OOPE.flatMap((item) =>
                item.Expenses.map((expense) => expense.attachment)
            );
            allAttachment.map((item, index) => {
                item.split('.').pop() === "pdf" ? fileDownload(item) : imgDownload(item, `expense_image_${index}`, "image/jpeg")
            })
            console.log("all  Attachment", allAttachment);
        } catch (err) {

        }
    }
    const fileDownload = (url) => {
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {

                const currentDate = new Date();
                const pdfBlob = new Blob([blob], { type: 'application/pdf' });
                console.log("blobbed:", pdfBlob);
                const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}`;
                saveAs(pdfBlob, `${window.location.pathname}_${formattedDate}.pdf`);
            })
            .catch((error) => console.error('Error downloading file:', error));
    };

    const filterMentor = () => {
        if (query.length > 0) {
            const filtered_OOPE = OOPE.filter((item, index) => {
                const name_mentor= item.mentor.name.toLowerCase();
                return name_mentor.startsWith(query.toLowerCase());
            })
            // setOOPE
            console.log("filtered_OOPE:", filtered_OOPE);
            setOOPE(filtered_OOPE);
        }else{
            getOOPE();
        }
    }


    console.log("data:", data)
    useEffect(() => {
        getOOPE();
    }, [])

    useEffect(() => {
        filterMentor();
    }, [query]);
    console.log("query:", query);
    return (
        loading ?
            <SmallPageLoader show={loading} />
            :
            <Box sx={{ p: '16px 90px 16px 138px', minWidth: '1311px', }}>
                <SideNavigationBar />
                <Box>
                    <Box sx={{ width: '100%', display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "40px" }}>
                        <Box sx={{ width: "25%", display: "flex", flexDirection: "row", justifyContent: 'flex-start', alignItems: "center" }}>
                            <ArrowBackIosNew onClick={() => navigate(-1)} sx={{ width: '34px', height: '34px', cursor: 'pointer' }} />
                            <Box sx={{ margin: "0px 6px 0px 12px" }}>
                                <Typography style={{ fontSize: '24px', fontWeight: 500, color: Color.blueBlack }}>Out of Pocket Expenses</Typography>
                                {/* <Typography style={{ fontSize: '16px', color: Color.neutralMidGrey }}>college_code </Typography> */}
                            </Box>
                        </Box>
                        <Box style={{ width: "75%", display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "12px" }}>
                            <TextField
                                style={{ backgroundColor: "#D8D7D7B2" }}
                                name="search_mentors"
                                // label="Search Student"
                                placeholder='Search by mentor name'
                                variant="outlined"
                                // onKeyPress={ }
                                value={ query }
                                onChange={(e) => setQuery(e.target.value)}
                                fullWidth
                                sx={{ width: '50%', borderRadius: "12px" }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search sx={{ color: Color.neutralMidGrey }} />
                                        </InputAdornment>
                                    ),
                                    sx: { borderRadius: "12px", height: "58px" }
                                }}
                            />
                            <Button variant="outlined" onClick={() => downloadCSV()} sx={{ ...styles.button_styles.outlined, fontSize: 18 }}>
                                {/* {loading ? <CircularProgress size={24} sx={{ color: styles.button.button1.color }} /> : button1.name} */}
                                Download CSV
                            </Button>

                            <Button variant="outlined" onClick={() => {
                                // fileDownload("https://profile-picture-and-logo.s3.amazonaws.com/Out-of-Pocket-Expense/2026_Resume-2.pdf") 
                                downloadmultiplefiles();
                            }} sx={{ ...styles.button_styles.contained, fontSize: 18 }}>
                                {/* {loading ? <CircularProgress size={24} sx={{ color: styles.button.button1.color }} /> : button1.name} */}
                                Download all Attachments
                            </Button>
                        </Box>
                    </Box>

                    <DataGrid
                        getRowHeight={() => 'auto'}
                        autoHeight
                        columns={[
                            {
                                field: 'Mentors',
                                headerName: 'Mentor',
                                width: 90,
                                flex: 0.2,
                                renderCell: (params) => (
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: 8 }}>
                                        {
                                            params.row.Mentors?.icon ?
                                                <Avatar
                                                    alt="mentor_icon"
                                                    src={params.row.Mentors?.icon}
                                                    sx={{ width: 36, height: 36 }}
                                                /> :
                                                <Avatar sx={{ bgcolor: Color.green }}>{params.row.Mentors?.name[0]}</Avatar>}
                                        <Typography style={{ margin: "0px 12px", fontSize: 16 }}>{params.row.Mentors?.name}</Typography>
                                    </div>

                                ),
                            },

                            {
                                field: 'Expenses',
                                flex: 0.3,
                                // headerClassName: 'super-app-theme--header',
                                headerAlign: 'center',
                                width: 140,
                                renderCell: (params) => (
                                    <div style={{ padding: 16, display: "flex", flexDirection: "column", alignItems: "center", }}>
                                        {
                                            params.row.Expenses.map((item, index) => {
                                                return (<div style={{ padding: 10, width: "70%" }}>
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <Avatar
                                                                alt="mentor_icon"
                                                                src={`https://du03hetco8xdw.cloudfront.net/offline/${encodeURIComponent(item.expense_type_name)}.svg`}
                                                                sx={{ width: 36, height: 36 }}
                                                            />
                                                            <div style={{ fontSize: 16, marginLeft: 10 }}>{item?.expense_type_name}</div>
                                                        </div>
                                                        <div style={{ padding: "0px 12px" }}>
                                                            ₹ {item?.price}
                                                        </div>
                                                    </div>
                                                    <Divider sx={{ borderColor: Color.neutralDarkGrey, color: "black", listStyle: 'none', marginBottom: ".5rem", marginTop: ".5rem" }} />
                                                </div>)
                                            })
                                        }
                                    </div>
                                ),
                            },
                            {
                                field: 'Attachments',
                                flex: 0.2,
                                // headerClassName: 'super-app-theme--header',
                                headerAlign: 'center',
                                width: 140,
                                renderCell: (params) => (
                                    <div onClick={() => { console.log("hetttt"); setViewAttachment((prev) => ({ ...prev, open: true, expenses: params.row.Expenses })) }} style={{ cursor: "pointer", fontSize: 18, color: Color.primary1, padding: 8, marginTop: 12, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <AttachFileIcon /> View Attachments
                                    </div>
                                    // <Button variant="outlined" sx={{ ...styles.button_styles.outlined, , borderRadius: 30, }} >
                                    //     ✔ Mark as Settled
                                    // </Button>
                                ),
                            },
                            {
                                field: 'Actions',
                                // headerClassName: 'super-app-theme--header',
                                headerAlign: 'center',

                                width: 140,
                                flex: 0.2,
                                renderCell: (params) => (
                                    <div >
                                        {params.row.Actions.is_settled === 0 ?
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Button onClick={() => { handlePutSettled(1, params.row.Mentors.mentor_id).then(() => { getOOPE(); }) }} variant="outlined" sx={{ ...styles.button_styles.outlined, fontSize: 18, borderRadius: 30, marginTop: "12px" }} >
                                                    ✔ Mark as Settled
                                                </Button></div>
                                            :
                                            <div style={{ fontSize: 18, color: Color.primary1, padding: 8, marginTop: 12, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <div style={{ fontSize: 20 }}> Settled  </div>
                                                <div onClick={() => { handlePutSettled(0, params.row.Mentors.mentor_id).then(() => { getOOPE(); }) }} style={{ cursor: "pointer", height: 40, width: 40, borderRadius: 40, backgroundColor: Color.primary1.concat("22"), textAlign: "center", marginLeft: "18px" }}><CloseIcon fontSize={"large"} /></div>
                                            </div>}
                                    </div>
                                ),
                            }]}
                        apiRef={apiRef}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        rows={[...OOPE].map((spe, spe_index) => ({
                            id: spe_index,
                            // avatar: spe.mentor,
                            Mentors: spe.mentor ?? 'Not yet selected',
                            Expenses: spe.Expenses,
                            Attachments: spe.Attachments,
                            Actions: spe.Action
                        }))} />
                    {viewAttachment.open && <ViewAttachmentModal
                        open={viewAttachment.open}
                        onClose={viewAttachment.onClose}
                        data={viewAttachment.expenses}
                    />}
                </Box>
            </Box>
    )
}

export default PocketExpenses

function ViewAttachmentModal({ open, onClose, data }) {
    const modalBoxStyle = {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "white",
        // padding: "1.5rem 2rem",
        borderRadius: "20px",
        width: "1000px",
        height: "600px",
        // minHeight: '250px'
    };

    const fileDownload = (url) => {
        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {

                const currentDate = new Date();
                const pdfBlob = new Blob([blob], { type: 'application/pdf' });
                console.log("blobbed:", pdfBlob);
                const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}`;
                saveAs(pdfBlob, `${window.location.pathname}_${formattedDate}.pdf`);
            })
            .catch((error) => console.error('Error downloading file:', error));
    };


    const imgDownload = (url, filename, mimeType) => {
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            })
            .then((blob) => {
                const currentDate = new Date();
                const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}`;
                const fullFilename = `${filename}_${formattedDate}`;

                // Ensure the blob has the correct MIME type for the image
                const imageBlob = new Blob([blob], { type: mimeType });
                saveAs(imageBlob, fullFilename);
            })
            .catch((error) => console.error('Error downloading file:', error));
    };

    const [selectedExpense, setSelectedExpense] = useState({ ...data[0] });
    console.log("do not falter in your path", data);

    return (
        <Modal open={open} >
            <Box sx={{ ...modalBoxStyle, justifyContent: 'space-between', }}>
                <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', width: '100%', height: "100%" }}>
                    <Box sx={{ width: "25%", borderRadius: "20px", height: "100%", backgroundColor: "#FBFAFA", }}>
                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 12, width: "100%", textAlign: "center", marginTop: 24 }}>
                            <Typography style={{ fontSize: 18, fontWeight: 500 }}>Attachments</Typography>
                            <Divider sx={{ borderColor: Color.blueBlack, borderWidth: 1, color: "black", height: 0, width: "90%", listStyle: 'none', marginBottom: ".2rem", }} />
                        </Box>
                        <Box>
                            {
                                data.map((item) => {
                                    return (
                                        <div>
                                            <Typography onClick={() => { setSelectedExpense({ ...item }) }} style={{ cursor: "pointer", display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10, padding: "4px 11px", fontSize: 16, backgroundColor: selectedExpense.timestamp === item.timestamp ? Color.gainsboro_200 : null }}>
                                                <Avatar
                                                    alt="mentor_icon"
                                                    src={`https://du03hetco8xdw.cloudfront.net/offline/${encodeURIComponent(item.expense_type_name)}.svg`}
                                                    sx={{ width: 36, height: 36, marginRight: "12px" }}
                                                />
                                                {item.expense_type_name + "." + item?.attachment.split('.').pop()}
                                            </Typography>
                                            {/* {
                                            item.timestamp ==selectedExpense.timestamp ? 
                                            <>
                                               <Divider sx={{ borderColor: Color.blueBlack, borderWidth:1, color: "black", height:0, width:"50%", listStyle: 'none',}} />
                                            </>:null
                                        } */}
                                        </div>
                                    )
                                })
                            }
                        </Box>
                    </Box>
                    <Box sx={{ width: "80%", padding: "8px" }}>
                        <Box onClick={onClose} style={{ cursor: "pointer", display: "flex", flexDirection: "row-reverse", padding: "16px" }}>
                            <CloseIcon />
                        </Box>
                        <Box
                            sx={{
                                ...DivCenter,
                                flexDirection: "column",
                                width: "95%",
                                height: "350px",
                                overflowY: "auto", // Changed to 'auto' to allow scrolling
                                background: "#fff",
                                zIndex: 2,
                                "&::-webkit-scrollbar": {
                                    display: "none", // Hide scrollbar for Chrome, Safari, Edge
                                },
                                scrollbarWidth: "none",
                                marginBottom: ".5rem",
                                boxShadow: "none",
                                borderBottomRightRadius: "6px",
                                borderBottomLeftRadius: "6px",
                            }}
                        >
                            {selectedExpense?.attachment.split('.').pop() === "pdf" ?
                                <PdfViewer
                                    url={selectedExpense.attachment}
                                /> :
                                <div style={{ width: "80%" }}>
                                    <img src={selectedExpense.attachment}
                                        style={{ width: "100%", overflow: "scroll" }} />
                                </div>
                            }
                        </Box>
                        <Box>
                            <Typography style={{ fontWeight: 500, fontSize: "18px" }}>Description</Typography>
                            <Typography style={{ fontSize: "16px" }}>{selectedExpense?.description}</Typography>
                        </Box>
                        <Box style={{ position: "absolute", bottom: 12, padding: "8px", width: "100%" }}>
                            <Box style={{ width: "60%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Button
                                    onClick={() => selectedExpense?.attachment.split('.').pop() === "pdf" ? fileDownload(selectedExpense?.attachment) : imgDownload(selectedExpense.attachment, "expense_image", "image/jpeg")}
                                    variant="contained" sx={{ ...styles.button_styles.contained, fontSize: 18, borderRadius: "12px", marginTop: "5px" }}>
                                    <FileDownloadOutlinedIcon />  Download Attachment
                                </Button>
                            </Box>
                        </Box>
                    </Box>

                </Box>
                {/* <Box sx={{ ...DivCenter, width: '100%' }}>
                    <Button variant="contained"

                        sx={{ width: '40%', minWidth: '150px', height: '54px', background: Color.primary_gradient }}>Submit</Button>
                </Box> */}
            </Box>
        </Modal>);
}