import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Color } from '../../../GlobalStyles'
import CardActions from '@mui/material/CardActions';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import Service from '../../../services/httpService';
import '../../Dashboard/OngoingEvents/OngoingEvents.css'
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import EventBusyIcon from "@mui/icons-material/EventBusy";
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import GroupChatModal from '../../GroupChatModal/GroupChatModal';
const OngoingEvents = (props) => {
    const service = new Service()
    const navigate = useNavigate();
    const location = useLocation();
    const { college_id, college_name } = props
    const [isExpanded, setIsExpanded] = useState(false);
    const [is1x1Expanded, setIs1x1Expanded] = useState(false);
    const [notScheduledExpanded, setIsNotScheduledExpanded] = useState(false)
    const [rescheduledExpanded, setIsRescheduledExpanded] = useState(false)
    const [cardDetails, setCardDetails] = useState([])
    const [expandedCardDetails, setExpandedCardDetails] = useState([])
    const [groupChat, setGroupChat] = useState(false);
    const [process_id, setProcess_id] = useState(null)
    const handleExpand = async (event, isAllDataExpanded, isExpanded, is1x1Expanded, notScheduledExpanded, index, process_id) => {
        console.log("data i'm accessing", isExpanded, is1x1Expanded, notScheduledExpanded, process_id);
        event.stopPropagation();
        let tempExpandedCardDetails = await expandedCardDetailsDataGetter(process_id, index);

        const updatedCardDetails = cardDetails.map((element, cardIndex) => {
            if (cardIndex === index) {
                return {
                    ...element,
                    done_count: tempExpandedCardDetails.done_count,
                    feedback_count: tempExpandedCardDetails.feedback_count,
                    in_process_count: tempExpandedCardDetails.in_process_count,
                    initialized_count: tempExpandedCardDetails.initialized_count,
                    initialized_expired_bookings_count: tempExpandedCardDetails.initialized_expired_bookings_count,
                    mentor_absent_count: tempExpandedCardDetails.mentor_absent_count,
                    purchased_count: tempExpandedCardDetails.purchased_count,
                    scheduled_count: tempExpandedCardDetails.scheduled_count,
                    student_absent_count: tempExpandedCardDetails.student_absent_count
                };
            }
            return element;
        });

        setCardDetails(updatedCardDetails);
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                isAllDataExpanded: !isAllDataExpanded,
                isExpanded: !isExpanded
            };
            return updatedBundleInfo;
        });
        setExpandedCardDetails(tempExpandedCardDetails);
        console.log("details", process_id, tempExpandedCardDetails, updatedCardDetails);
    };

    const handleGroupChatOpen = (e, process_id) => {
        console.log("process id", process_id);
        
        e.stopPropagation()
        setProcess_id(process_id)
        setGroupChat(true);
    };

    const handleGroupChatClose = () => {
        setGroupChat(false);
    };

    const closeIs1x1Expanded = (event, is1x1Expanded, index) => {
        event.stopPropagation();
        console.log("data i'm accessing", isExpanded, is1x1Expanded, notScheduledExpanded);
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                is1x1Expanded: !is1x1Expanded,
            };
            return updatedBundleInfo;
        });
    }

    const closeNotScheduledExpanded = (event, notScheduledExpanded, index) => {
        event.stopPropagation();
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                notScheduledExpanded: !notScheduledExpanded,
            };
            return updatedBundleInfo;
        });
    }

    const closeIsExpanded = (event, isExpanded, is1x1Expanded, notScheduledExpanded, index) => {
        event.stopPropagation();
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                isExpanded: !isExpanded,
                // is1x1Expanded: false,
                // notScheduledExpanded: false
            };
            return updatedBundleInfo;
        });
    }

    const closeRescheduledExpanded = (event, isExpanded, is1x1Expanded, notScheduledExpanded, rescheduledExpanded, index) => {
        event.stopPropagation();
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                rescheduledExpanded: !rescheduledExpanded,
            };
            return updatedBundleInfo;
        });
    }

    // useeffect'
    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {
            console.log("env", process.env.REACT_APP_RD_API_URL, process.env.REACT_APP_API_URL)
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }
            //college getter
            let college = await collegeDataGetter();
            let filtered_college_list = college.filter(item => item.sku_name !== "Resumes.Digital 1 year")
            filtered_college_list?.forEach(element => {
                element.process_id = element.process_id;
                element.isExpanded = false;
                element.is1x1Expanded = false;
                element.notScheduledExpanded = false;
                element.isAllDataExpanded = false;
                element.rescheduledExpanded = false;
                element.loadingButton = false;
            });



            setCardDetails(filtered_college_list)
            // forming the data
            console.log("odonil", { college })
        }

        handleEffect();
    }, [])

    const collegeDataGetter = async () => {
        try {
            const res = await service.get(`/ongoingEvents?college_id=${college_id}`)
            console.log("college details response:", res.data)
            // setCardDetails([...res.data])
            return res.data
        } catch (err) {
            console.log(err)
        }
    }

    const expandedCardDetailsDataGetter = async (process_id, index) => {
        setCardDetails(prevCardInfo => {
            const updatedBundleInfo = [...prevCardInfo];
            updatedBundleInfo[index] = {
                ...updatedBundleInfo[index],
                loadingButton: true
            };
            return updatedBundleInfo;
        });
        try {
            const res = await service.get(`CollegeOngoingEventsById?process_id=${process_id}`)
            console.log("extended details response:", res.data)
            // setCardDetails([...res.data])
            setCardDetails(prevCardInfo => {
                const updatedBundleInfo = [...prevCardInfo];
                updatedBundleInfo[index] = {
                    ...updatedBundleInfo[index],
                    loadingButton: false
                };
                return updatedBundleInfo;
            });
            return res.data
        } catch (err) {
            console.log(err)
        }
    }

    console.log("crad details", cardDetails);
    console.log("expanded card details", Object.entries(expandedCardDetails));
    return (
        <div>
            <Typography style={{ marginBottom: "2rem",marginTop:"2rem" }} sx={{ fontSize: 20 }}>Ongoing Events</Typography>
            <div className="cardsInsideFlex" style={{width:cardDetails.length === 0 ? "85%" : "100%"}} >
                {cardDetails.length === 0 ? <>
                    <div className="noUpcomingBookings" style={{ width: "80%", justifyContent: "center", alignItems: 'center', margin: "0 auto" }}>
                        <div className="noUpcomingBookingsIcon">
                            <EventBusyIcon
                                sx={{
                                    color: "#1C8EA8",
                                    width: "50px",
                                    height: "50px",
                                }}
                            />
                        </div>
                        <div className="noUpcomingBookingsTexts">
                            <p className="noUpcomingBookingsText">
                                No Ongoing Events
                            </p>
                        </div>
                    </div>
                </> : <>
                    {cardDetails?.map((details, index) => {
                        return (
                            <>
                                <Box sx={{ width: 255, marginRight: '1rem', marginLeft: '.2rem', marginBottom: '.2rem', boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)", borderRadius: "10px", display: "flex", height: details.isAllDataExpanded ? "auto" : 272, cursor: "pointer" }}
                                    onClick={details?.isAllDataExpanded === true ? () => { } : () => navigate("/College1x1StudentDetails?college_name=" + college_name + "&batchName=" + details.user_group_name, { state: { college_name: college_name, batchName: details.user_group_name, batchId: details.user_group_id, process_id: details.process_id, sku_name: details.sku_name, searched: "All" } })}
                                >
                                    <CardContent className="cardContainer" disableGutters style={{ width: index === 0 ? "255px" : '255px' }}>
                                        <Typography variant="body2" sx={{ fontSize: 20, marginBottom: "1rem" }} gutterBottom>
                                            {details.sku_name.substring(0, 15).concat(details.sku_name.length > 15 ? "..." : "")}
                                        </Typography>
                                        <Typography variant="h5" component="div"></Typography>
                                        <div className="container">
                                            <Typography variant="body2" class='providingSpace1'>Batch:</Typography>
                                            <Typography variant="body2" class='providingSpace'>{details.user_group_name}</Typography>
                                        </div>
                                        <div className="container">
                                            <Typography variant="body2" class='providingSpace1'>Total Students:</Typography>
                                            <Typography variant="body2" class='providingSpace'>{details.total_student}</Typography>
                                        </div>
                                        {details.isAllDataExpanded && (
                                            <>
                                                {/* {expandedCardDetails.map(items => {
                                           
                                            return ( */}
                                                <>
                                                    <div className="containerHeadings">
                                                        <Typography variant="body2" sx={{ fontSize: 18 }} color={Color.neutralDarkGrey}>Key Updates</Typography>
                                                        <button onClick={(e) => closeIsExpanded(e, details.isExpanded, details.is1x1Expanded, details.notScheduledExpanded, index)} className='show-hide-button' variant="body2" sx={{ fontSize: 16 }} color={Color.primary1}>{details.isExpanded ? "Hide" : "Show"}</button>
                                                    </div>
                                                    {
                                                        details.isExpanded && (
                                                            <>

                                                                <div>
                                                                    <div className="container" onClick={() => navigate("/College1x1StudentDetails?college_name=" + college_name + "&batchName=" + details.user_group_name, { state: { college_name: college_name, batchName: details.user_group_name, batchId: details.user_group_id, process_id: details.process_id, sku_name: details.sku_name, searched: "Done" } })}>
                                                                        <Typography variant="body2" color={Color.primary1}>1x1 Done:</Typography>
                                                                        <Typography variant="body2" class="providingSpaceForSubDetails">{details.done_count}</Typography>
                                                                    </div>
                                                                    <div className="container" onClick={() => navigate("/College1x1StudentDetails?college_name=" + college_name + "&batchName=" + details.user_group_name, { state: { college_name: college_name, batchName: details.user_group_name, batchId: details.user_group_id, process_id: details.process_id, sku_name: details.sku_name, searched: "Mentor Absent" } })}>
                                                                        <Typography variant="body2" color={Color.primary1}>Mentor Absent:</Typography>
                                                                        <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.mentor_absent_count}</Typography>
                                                                    </div>
                                                                    <div className="container" onClick={() => navigate("/College1x1StudentDetails?college_name=" + college_name + "&batchName=" + details.user_group_name, { state: { college_name: college_name, batchName: details.user_group_name, batchId: details.user_group_id, process_id: details.process_id, sku_name: details.sku_name, searched: "Scheduled" } })}>
                                                                        <Typography variant="body2" color={Color.primary1}>Scheduled:</Typography>
                                                                        <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetails">{details.scheduled_count}</Typography>
                                                                    </div>
                                                                    <div className="container" onClick={() => navigate("/College1x1StudentDetails?college_name=" + college_name + "&batchName=" + details.user_group_name, { state: { college_name: college_name, batchName: details.user_group_name, batchId: details.user_group_id, process_id: details.process_id, sku_name: details.sku_name, searched: "Not Scheduled" } })}>
                                                                        <Typography variant="body2" color={Color.primary1}>Not Scheduled:</Typography>
                                                                        <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetails">{details.purchased_count + details.initialized_expired_bookings_count}</Typography>
                                                                    </div>
                                                                    <div className="container" onClick={() => navigate("/College1x1StudentDetails?college_name=" + college_name + "&batchName=" + details.user_group_name, { state: { college_name: college_name, batchName: details.user_group_name, batchId: details.user_group_id, process_id: details.process_id, sku_name: details.sku_name, searched: "Mentor Awaited" } })}>
                                                                        <Typography variant="body2" color={Color.primary1}>Mentor Awaited:</Typography>
                                                                        <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.initialized_count - details.initialized_expired_bookings_count}</Typography>
                                                                    </div>

                                                                    <div className="container" onClick={() => navigate("/College1x1StudentDetails?college_name=" + college_name + "&batchName=" + details.user_group_name, { state: { college_name: college_name, batchName: details.user_group_name, batchId: details.user_group_id, process_id: details.process_id, sku_name: details.sku_name, searched: "Ongoing" } })}>
                                                                        <Typography variant="body2" color={Color.primary1}>Ongoing:</Typography>
                                                                        <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.in_process_count}</Typography>
                                                                    </div>
                                                                </div>

                                                            </>
                                                        )
                                                    }
                                                    <Divider component="li" sx={{ listStyle: 'none', marginBottom: ".5rem", marginTop: ".5rem" }} />
                                                    <div className="containerHeadings">
                                                        <Typography variant="body2" sx={{ fontSize: 18 }} color={Color.neutralDarkGrey}>1x1 Done</Typography>
                                                        <button onClick={(e) => closeIs1x1Expanded(e, details.is1x1Expanded, index)} className='show-hide-button' variant="body2" sx={{ fontSize: 16 }} color={Color.primary1}>{details.is1x1Expanded ? "Hide" : "Show"}</button>
                                                    </div>
                                                    {
                                                        details.is1x1Expanded && (
                                                            <>
                                                                <div className="container" onClick={() => navigate("/College1x1StudentDetails?college_name=" + college_name + "&batchName=" + details.user_group_name, { state: { college_name: college_name, batchName: details.user_group_name, batchId: details.user_group_id, process_id: details.process_id, sku_name: details.sku_name, searched: "Awaited Mentor Feedback" } })}>
                                                                    <Typography variant="body2" color={Color.primary1}>Awaited Mentor Feedback: </Typography>
                                                                    <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.feedback_count}</Typography>
                                                                </div>
                                                                <div className="container" onClick={() => navigate("/College1x1StudentDetails?college_name=" + college_name + "&batchName=" + details.user_group_name, { state: { college_name: college_name, batchName: details.user_group_name, batchId: details.user_group_id, process_id: details.process_id, sku_name: details.sku_name, searched: "Received Mentor Feedback" } })}>
                                                                    <Typography variant="body2" color={Color.primary1}>Received Mentor Feedback:</Typography>
                                                                    <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.done_count - details.student_absent_count}</Typography>
                                                                </div>
                                                                <div className="container" onClick={() => navigate("/College1x1StudentDetails?college_name=" + college_name + "&batchName=" + details.user_group_name, { state: { college_name: college_name, batchName: details.user_group_name, batchId: details.user_group_id, process_id: details.process_id, sku_name: details.sku_name, searched: "Student Absent" } })}>
                                                                    <Typography variant="body2" color={Color.primary1}>Student Absent:</Typography>
                                                                    <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.student_absent_count}</Typography>
                                                                </div>

                                                            </>
                                                        )
                                                    }
                                                    <Divider component="li" sx={{ listStyle: 'none', marginBottom: ".5rem", marginTop: ".5rem" }} />

                                                    <div className="containerHeadings">
                                                        <Typography variant="body2" sx={{ fontSize: 18 }} color={Color.neutralDarkGrey}>Not Scheduled</Typography>
                                                        <button onClick={(e) => closeNotScheduledExpanded(e, details.notScheduledExpanded, index)} className='show-hide-button' variant="body2" sx={{ fontSize: 16 }} color={Color.primary1}>{details.notScheduledExpanded ? "Hide" : "Show"}</button>
                                                    </div>
                                                    {
                                                        details.notScheduledExpanded && (
                                                            <>
                                                                <div className="container" onClick={() => navigate("/College1x1StudentDetails?college_name=" + college_name + "&batchName=" + details.user_group_name, { state: { college_name: college_name, batchName: details.user_group_name, batchId: details.user_group_id, process_id: details.process_id, sku_name: details.sku_name, searched: "No Action Students" } })}>
                                                                    <Typography variant="body2" color={Color.primary1}>No Action Students: </Typography>
                                                                    <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.purchased_count}</Typography>
                                                                </div>
                                                                <div className="container" onClick={() => navigate("/College1x1StudentDetails?college_name=" + college_name + "&batchName=" + details.user_group_name, { state: { college_name: college_name, batchName: details.user_group_name, batchId: details.user_group_id, process_id: details.process_id, sku_name: details.sku_name, searched: "Expired Bookings" } })}>
                                                                    <Typography variant="body2" color={Color.primary1}>Expired Bookings:</Typography>
                                                                    <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">{details.initialized_expired_bookings_count}</Typography>
                                                                </div>
                                                            </>
                                                        )
                                                    }


                                                    <Divider component="li" sx={{ listStyle: 'none', marginBottom: ".5rem", marginTop: ".5rem" }} />

                                                    <div className="containerHeadings">
                                                        <Typography variant="body2" sx={{ fontSize: 18 }} color={Color.neutralDarkGrey}>Rescheduled</Typography>
                                                        <button onClick={(e) => closeRescheduledExpanded(e, details.isExpanded, details.is1x1Expanded, details.notScheduledExpanded, details.rescheduledExpanded, index)} className='show-hide-button' variant="body2" sx={{ fontSize: 16 }} color={Color.primary1}>{details.rescheduledExpanded ? "Hide" : "Show"}</button>
                                                    </div>
                                                    {details.rescheduledExpanded && (
                                                        <>
                                                            <div className="container">
                                                                <Typography variant="body2" color={Color.primary1}>Rescheduled by student: </Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                            </div>
                                                            <div className="container">
                                                                <Typography variant="body2" color={Color.primary1}>Rescheduled by mentor:</Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                            </div>
                                                            <div className="container">
                                                                <Typography variant="body2" color={Color.primary1}>Mentor’s Pending reschedule request:</Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                            </div>
                                                            <div className="container">
                                                                <Typography variant="body2" color={Color.primary1}>Student’s Pending reschedule request:</Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                            </div>
                                                            <div className="container">
                                                                <Typography variant="body2" color={Color.primary1}>Rejected Requests by student:</Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                            </div>
                                                            <div className="container">
                                                                <Typography variant="body2" color={Color.primary1}>Rejected Requests by mentor:</Typography>
                                                                <Typography variant="body2" color={Color.red} class="providingSpaceForSubDetailsRed">N/A</Typography>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                                {/* ); */}
                                                {/* // })} */}

                                            </>
                                        )}
                                        {details.isAllDataExpanded === false && (
                                            <CardActions sx={{ justifyContent: 'flex-end' }}>
                                                <button
                                                    className="submitBtn"
                                                    type="button"
                                                    style={{ height: "44px", bottom: 0, marginTop: details.isAllDataExpanded ? "0px" : "0" }}
                                                    onClick={(event) => handleExpand(event, details.isAllDataExpanded, details.isExpanded, details.is1x1Expanded, details.notScheduledExpanded, index, details.process_id)}
                                                >
                                                    {details.loadingButton ? <CircularProgress size={24} color="inherit" /> : "Load Details"}
                                                </button>
                                            </CardActions>

                                        )}
                                        <CardActions sx={{ justifyContent: 'flex-end', bottom: 0, }}>
                                            <button
                                                className="submitBtn"
                                                type="button"
                                                style={{ height: "44px", bottom: 0, background: Color.white, border: `1px solid ${Color.primary1}`, color: Color.primary1 }}
                                                onClick={(e) => handleGroupChatOpen(e, details.process_id)}
                                            >
                                                {details.loadingButton ? <CircularProgress size={24} color="inherit" /> : <Typography><TextsmsOutlinedIcon sx={{ color: Color.primary1 }} /> Mentor Chat Room </Typography>}
                                            </button>
                                        </CardActions>
                                    </CardContent>
                                </Box>
                            </>
                        )
                    })}
                </>}

            </div>
            {groupChat && <GroupChatModal modalOpen={groupChat} handleModalClose={handleGroupChatClose} process_id={process_id} />}

        </div>
    )
}

export default OngoingEvents
