import React, { useState, useEffect } from 'react';
import { Container, Typography, Card, CardContent, Box, Modal, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import Service from '../../../services/httpService';
import CollegeData from '../../../CollegeDataServices/httpCollege';
import '../../Dashboard/OngoingEvents/OngoingEvents.css';
import { Color } from '../../../GlobalStyles';
import AddIcon from '@mui/icons-material/Add';

const MentorStatus = (props) => {
  const service = new Service();
  const navigate = useNavigate();
  const [collegeData, setCollegeData] = useState([]);
  const collegeEndPoint = new CollegeData();
  const [formInputs, setFormInputs] = useState({ id: null, college_name: "", college_code: "" });
  const [show, setShow] = useState(false);
  const [selectedCollegeIndex, setSelectedCollegeIndex] = useState(null);

  const { activityTypeData, durationData, mentorData, collegeDetails, batchData, entireGroupSessionData, path } = props;

  useEffect(() => {
    const fetchCollegeData = async () => {
      if (!Cookies.get('token')) {
        navigate('/');
        return;
      }
      try {
        const res = await service.get("/getCollegeWithstudentCount");
        console.log("what is here",res.data);
        const sortedColleges = [...res.data].sort((a, b) =>
          a.college_name.localeCompare(b.college_name)
      )
        setCollegeData(sortedColleges);
      } catch (err) {
        console.error(err);
      }
    };
    fetchCollegeData();
  }, []);

  useEffect(() => {
    if (selectedCollegeIndex !== null) {
      const college = collegeData[selectedCollegeIndex];
      setFormInputs({
        id: college.id,
        college_name: college.college_name,
        college_code: college.college_code,
      });
    }
  }, [selectedCollegeIndex, collegeData]);

  const handleEditModal = (e, index) => {
    if (index === null) {
      setFormInputs({
        id: null,
        college_name: "",
        college_code: "",
      });
    }
    e.preventDefault();
    e.stopPropagation();
    setSelectedCollegeIndex(index);
    setShow(true);
  };

  const handleModalClose = () => {
    setShow(false);
    setSelectedCollegeIndex(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInputs(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      if (selectedCollegeIndex === null) {
        let data = {
          college_name: formInputs.college_name,
          college_code: formInputs.college_code
        }
        const res = await collegeEndPoint.post("/college", data);
        setCollegeData(prevData => [{ ...formInputs, id: res.data.id }, ...prevData]);
      } else {
        await collegeEndPoint.put("/college", formInputs);
        setCollegeData(prevData => prevData.map((college, index) => index === selectedCollegeIndex ? formInputs : college));
      }
      handleModalClose();
    } catch (err) {
      console.error("Error:", err);
      window.alert(err.response?.data?.message || "An error occurred");
    }
  };

  return (
    <div>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "80%" }}>
        <Typography sx={{ fontSize: 20 }} style={{ marginBottom: ".5rem", marginTop: ".5rem" }}>Colleges</Typography>
        <AddIcon onClick={(e) => handleEditModal(e, null)} sx={{
          height: '30px',
          borderRadius: "100%",
          width: '30px',
          padding: '5px',
          marginBottom: ".5rem", marginTop: ".5rem",
          cursor:"pointer",
          opacity: '50%', alignItems:"center", justifyContent:"center",
          ':hover': { bgcolor: '#D9D9D9', opacity: '100%' }
        }} />
      </Box>
      <div className="cardsInsideFlex">
        {collegeData.map((college, index) => (
          <Card
            key={college.id}
            sx={{
              width: 240,
              margin: '0.2rem 1rem 0.2rem 0.2rem',
              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.08)",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => navigate("/CollegeInfoPage?college_id=" + college.id + "&college_name=" + college.college_name, { state: { path: path, entireGroupSessionData: entireGroupSessionData, batchData: batchData, collegeDetails: college, mentorData: mentorData, durationData: durationData, activityTypeData: activityTypeData } })}
          >
            <CardContent sx={{ padding: "12px", '&:last-child': { paddingBottom: "12px" } }}>
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: 16, color: Color.neutralDarkGrey , fontWeight:"medium"}} align="left" variant="body1">
                  {college.college_name}
                </Typography>
                <EditIcon
                  onClick={(e) => handleEditModal(e, index)}
                  sx={{
                    color: '#BFBFBF',
                    height: '30px',
                    borderRadius: "100%",
                    width: '30px',
                    padding: '5px',
                    opacity: '50%',
                    ':hover': { bgcolor: '#D9D9D9', opacity: '100%' }
                  }}
                />
              </Box>
              <div className="container" style={{ display: "flex", justifyContent: "left", flexDirection: "row", fontSize: 16 , padding:"0px", margin:"0px"}}>
                <Typography sx={{ fontSize: 16, color: Color.neutralDarkGrey }} align="left" variant="body1">
                  {college.college_code}
                </Typography>
                <Typography>|</Typography>
                <Typography variant="body2" sx={{ fontSize: 16, color: Color.neutralDarkGrey }}>{college.total_user_group + " Batches"}</Typography>
                {/* <Typography>|</Typography> */}
              </div>
                <Typography variant="body2" sx={{ fontSize: 16, color: Color.neutralDarkGrey }}>{college.total_students + " Students"}</Typography>
            </CardContent>
          </Card>
        ))}

        <Modal
          open={show}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
          }}>
            <form className="Entire-form" style={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  College Data
                </Typography>
                <CloseIcon className="closeIcon" onClick={handleModalClose} />
              </Box>
              <div className="form-group">
                <label className="inputLabel">College Name<span>*</span></label>
                <input name="college_name" type="text" className="form-control" placeholder="Example input" value={formInputs.college_name} onChange={handleChange} />
              </div>
              <div className="form-group">
                <label className="inputLabel">College Code<span>*</span></label>
                <input name="college_code" type="text" className="form-control" placeholder="Example input" value={formInputs.college_code} onChange={handleChange} />
              </div>
              <Button variant="contained" sx={{
                backgroundColor: Color.primary3,
                marginTop: '1rem',
                textDecoration: "none",
                textTransform: "none",
                boxShadow: "none",
                '&:hover': {
                  backgroundColor: Color.primary3,
                  boxShadow: "none",
                }
              }} onClick={handleSubmit} style={{ marginTop: '1rem' }}>
                Submit
              </Button>
            </form>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default MentorStatus;
