import React, { useEffect, useState } from "react";
import ServiceCM from '../../../services/cartmoduleServices';
import Service from '../../../services/httpService';
import '../../reusables/ModalInput/ModalInputStyles.css';
import EnhancedTable from '../../reusables/TableComponent/TableComponent';
import Papa from 'papaparse';
import { saveAs } from "file-saver";

import Modal from "react-bootstrap/Modal";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/Delete';
import { IconButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { send_specialCharReplace, get_specialCharReplace } from "../../../services/specialCharReplace";
import CloseIcon from '@mui/icons-material/Close';
import TopBar from '../../reusables/TopBar/Topbar'
import SideNavigationBar from '../../reusables/SideNavigationBar/SideNavigationBar';
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from 'js-cookie';
import Loader from "../../reusables/Loader/Loader";
import { UserTypeContext as RowsContext } from '../RowsContext/RowsContext';
const SKU = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [rowsData, setRowsData] = useState([])
    const [skuData, setSkuData] = useState([])
    const [activityData, setActivityData] = useState([])
    const [durationData, setDurationData] = useState([])
    const [formInputs, setFormInputs] = useState({ act_type_id: "", name: "", description: "", duration: "", price: "", actual_price:"", marketing_description: [] })
    //get current date and time
    const currentDate = new Date();


    const [from_table, setFrom_table] = useState();

    const [JDfiles, setJDFiles] = useState([]);

    const serviceCM = new ServiceCM()
    const serviceSA = new Service()
    let count = 0;
    const headCells = [
        {
            id: 'heading1',
            numeric: 'right',
            disablePadding: false,
            label: 'Sku Id'
        },
        {
            id: 'heading1',
            numeric: 'right',
            disablePadding: false,
            label: 'Activity Name'
        },
        {
            id: 'heading2',
            numeric: 'right',
            disablePadding: false,
            label: 'Sku Name'
        },
        {
            id: 'heading3',
            numeric: 'right',
            disablePadding: false,
            label: 'Description'
        },
        {
            id: 'heading4',
            numeric: 'right',
            disablePadding: false,
            label: 'Duration'
        },
        {
            id: 'heading5',
            numeric: 'right',
            disablePadding: false,
            label: 'Price'
        },
        {
            id: 'heading5',
            numeric: 'right',
            disablePadding: false,
            label: 'Actual price'
        }

    ];

    useEffect(() => {
        console.log("Brother")
        const handleEffect = async () => {
            if (Cookies.get('token') === null || Cookies.get('token') === undefined) {
                navigate('/');
            }
            let sku = await SKUGetter();
            let activity = await activityGetter();
            let duration = await durationGetter();
            console.log("odonil", { sku }, { activity }, { duration })
            data_manupulator_sku(sku, activity);
            setLoading(false)
        }
        handleEffect();
    }, [])

    useEffect(() => {
        const setting_data = () => {

            if (from_table?.index === undefined) {
                console.log("Inside if of useEffect")
                setFormInputs({ act_type_id: "Select Activity Type", name: "", description: "", duration: "", price: "", actual_price: "", marketing_description: [""] })
            }
            else {
                console.log("Inside else of useEffect")
                console.log({ rowsData })
                setFormInputs({ ...formInputs, act_type_id: skuData[from_table?.index]?.act_type_id, name: skuData[from_table?.index]?.name, description: skuData[from_table?.index]?.description, duration: skuData[from_table?.index]?.duration, price: skuData[from_table?.index]?.price, actual_price: skuData[from_table?.index]?.actual_price, marketing_description: JSON.parse(skuData[from_table?.index]?.marketing_description)?.why_this ? JSON.parse(skuData[from_table?.index]?.marketing_description)?.why_this : [""] })
            }
        }
        setting_data();
    }, [from_table])

    const handleDeleteMul = (index, id) => {
        console.log("delete ~ id:", id)
        let list_rowsData = [...rowsData];
        let list_skuData = [...skuData];


        serviceCM
            .delete("/sku", {
                data: { "ids": id }
            })
            .then((res) => {
                console.log("Response from backend is", res);
                res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                list_rowsData = list_rowsData.filter((item) => id.indexOf(item.id) < 0);
                setRowsData(list_rowsData)
                list_skuData = list_skuData.filter((item) => id.indexOf(item.id) < 0);
                setSkuData(list_skuData)

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
            });
    }

    const data_getter = (name, id, index, activity) => {

        console.log("Dude here's your name:", name);
        console.log("Dude here's your id:", id);
        console.log("Dude here's your index:", index);

        if (name === "delete_mul") {
            console.log("inside delete mul if")
            handleDeleteMul(index, id)
        }
        if (activity === "add") {
            setFormInputs({})
        }
        setFrom_table({ ...from_table, id: typeof id === "number" ? id : undefined, index: typeof index === "number" ? index : undefined })
    }

    const SKUGetter = async () => {
        try {
            const res = await serviceCM.get('/sku')
            console.log("Duration RESPONSE:", res.data)
            setRowsData([...res.data])
            setSkuData([...res.data])
            return res.data
        } catch (err) {
            console.log(err)
        }
    }
    const activityGetter = async () => {
        try {
            const res = await serviceSA.get('/activityType')
            console.log("Activity RESPONSE:", res.data)
            setActivityData([...res.data])
            return res.data
        } catch (err) {
            console.log(err)
        }
    }
    const durationGetter = async () => {
        try {
            const res = await serviceSA.get('/duration')
            console.log("Duration RESPONSE:", res.durations)
            setDurationData([...res.durations])
            return res.durations
        } catch (err) {
            console.log(err)
        }
    }



    const data_manupulator_sku = (sku, activity) => {
        console.log("SKU data:", sku)
        console.log("Activity data:", activity)
        let temp_rowsData = JSON.parse(JSON.stringify(sku));
        let temp_activity = JSON.parse(JSON.stringify(activity));


        temp_rowsData = temp_rowsData.map((row) => {
            row.act_type_id = temp_activity?.map(act => act.id === row.act_type_id ? act.act_type_name : "").filter(value => value !== "")[0]
            // delete row.act_type_id
            delete row.is_deleted
            delete row.features
            delete row.type
            delete row.marketing_description
            delete row.image_url
            return row
        });
        console.log("temp rows:", temp_rowsData)
        setRowsData(temp_rowsData)

    }




    const Modal_SKU = (show, setShow, mode) => {

        // useEffect(() => {
        //     console.log("Brother")
        //     const handleEffect = async () => {

        //         setFormInputs({...formInputs, act_type_id: skuData[from_table?.index].act_type_id})

        //     }

        //     handleEffect();
        // }, [from_table?.index])

        function handleFileInputChange(event) {
            const file = event.target.JDfiles[0];
            console.log(file);
        }

        const modalSubmit = () => {
            if (from_table.index === undefined)
                handlePostRequest();
            else
                handlePutRequest();

            setShow(false)
        }



        const handleFileInput = (event) => {
            const f = event.target.files;
            let filesArray = [...JDfiles];
            for (let i = 0; i < f.length; i++) {
                filesArray.push(f[i]);
            }
            setJDFiles(filesArray);
        };

        const handleRemoveFile = (fileIndex) => {
            console.log("Removed id:", rowsData[from_table?.index]?.id)

            try {
                let res = serviceCM.delete("/job_name/file",
                    {
                        data: {
                            jd_id: rowsData[from_table?.index]?.id
                        }
                    })


                console.log("Response from backend is", JSON.stringify(res, null, 2));
                res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                const filesArray = [...JDfiles];
                filesArray.splice(fileIndex, 1);
                setJDFiles(filesArray);

            } catch (err) {
                console.log("Errog Msg: ", err);

            }






        };


        // handle add and delete of description
        const handleAddDesc = () => {
            let tempFormInputs = {
                ...formInputs,
                marketing_description: [...formInputs.marketing_description, ""]
            }
            setFormInputs(tempFormInputs)
        }
        const handleDeleteDesc = (index) => {
            let tempFormInputs = formInputs.marketing_description.filter((s, i) => i !== index);

            // create a new state with the updated hobbies array
            let temp_FI = {
                ...formInputs,
                marketing_description: tempFormInputs
            };

            // update the state using setMyState
            setFormInputs(temp_FI);
        }





        function handleFileInputChange(event) {
            const file = event.target.JDfiles[0];
            console.log(file);
        }




        const onHide = () => {
            setShow(false)
        }


        const handleChange = (e, index) => {

            console.log("name from form  is:", e.target.name);
            console.log("value from form  is:", e.target.value);
            const { name, value } = e.target

            if (name === "act_type_id") {
                console.log("act_type_id")
                setFormInputs({ ...formInputs, act_type_id: parseInt(value) })
            }

            else if (name === "name") {
                console.log("name")
                setFormInputs({ ...formInputs, name: value })
            }
            else if (name === "description") {
                console.log("description")
                setFormInputs({ ...formInputs, description: value })
            }
            else if (name === "duration") {
                console.log("header_desc")
                setFormInputs({ ...formInputs, duration: value })
            }
            else if (name === "price") {
                console.log("price")
                setFormInputs({ ...formInputs, price: value })

            } else if (name === "actual_price") {
                console.log("actual_price")
                setFormInputs({ ...formInputs, actual_price: value })

            } else if (name === "marketing_description") {
                console.log("marketing_description")
                let temp_footer_desc = [...formInputs.marketing_description];
                temp_footer_desc[index] = value;
                console.log("hahaha", temp_footer_desc)
                setFormInputs(prevState => ({ ...prevState, marketing_description: temp_footer_desc }));
            }
        }

        const onSelect_durations = (e) => {
            console.log("Added an item to multi select - program -", e.duration_name)
            setFormInputs({ ...formInputs, duration: e.duration_name });

            // let temp_value = JSON.parse(JSON.stringify(e));
            // temp_value = temp_value.filter(data => typeof data.duration_name !== "number").map(obj => obj.duration_name)
            // console.log("temp inside onSelect:", temp_value)
            // setPost_durationData(temp_value) //data to be sent to the backend ~ post/put call
        }

        const onRemove_durations = (e) => {
            console.log("Removed an item to multi select", e)
            setFormInputs({ ...formInputs, duration: e.duration_name });

            // let temp_value = JSON.parse(JSON.stringify(e));
            // temp_value = temp_value.filter(data => typeof data.duration_name !== "number").map(obj => obj.duration_name)
            // console.log("temp inside onSelect:", temp_value)
            // setPost_durationData(temp_value)  //data to be sent to the backend ~ post/put call

        }


        return (
            <Modal
                show={show}
                onHide={() => onHide()}
                className="FileUploadModal"
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ backdropFilter: "blur(5px)", minWidth: "100%", maxWidth: "100%", minHeight: "100%", maxHeight: "95%" }}
            >
                <Modal.Body style={{
                    position: 'absolute',
                    left: '60%',
                    transform: 'translate(-50%, -50%)',
                    lineHeight: '1.4',
                    padding: '14px 28px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    maxWidth: '95%',
                    minWidth: '500px',
                    minHeight: '300px'
                }}>
                    <form className="Entire-form" style={{ display: 'flex', flexDirection: 'col' }}>

                        <div className='right-side-buttons-sku row'>
                            <div className="headerComp" >
                                <CloseIcon className="closeIcon" onClick={((e) => onHide(e))} />
                                <p className="compTitle">SKU</p>
                            </div>
                            <div className="form-group">
                                <label className="inputLabel">Activity Type<span>*</span></label>
                                {/* <input name="durations_name" type="text" className="form-control" placeholder="Example input" value={formInputs.duration_name} onChange={(e) => handleChange(e)} /> */}
                                <select name="act_type_id" disabled={mode === "View" ? true : false} id="selectList" className="form-control inputFields" onChange={(e) => handleChange(e)} value={formInputs?.act_type_id}>
                                    <option value={""} selected>Choose Activity Type</option>
                                    {activityData?.map((act, i) => (
                                        <option key={i} value={act.id}>{act.act_type_name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="inputLabel">Name<span>*</span></label>
                                <input name="name" type="text" disabled={mode === "View" ? true : false} className="form-control" placeholder="Example input" value={formInputs.name} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className="form-group">
                                <label className="inputLabel">Description<span>*</span></label>
                                <textarea name="description" type="text" style={{ height: "auto", overflow: "visible", boxSizing: 'border-box' }} disabled={mode === "View" ? true : false} className="form-control" placeholder="Example input" value={formInputs.description} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className="form-group">
                                <label className="inputLabel">Duration<span>*</span></label>
                                {/* <input name="durations_values" type="text" className="form-control" placeholder="Example input" value={formInputs.duration} onChange={(e) => handleChange(e)} /> */}
                                <select name="duration" id="selectList" disabled={mode === "View" ? true : false} className="form-control inputFields" onChange={(e) => handleChange(e)} value={formInputs?.duration}>
                                    <option value={""} selected>Choose Duration</option>
                                    {durationData?.map((duration, i) => (
                                        <option key={i} value={duration.duration_name}>{duration.duration_name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label className="inputLabel">Price<span>*</span></label>
                                <input name="price" type="text" className="form-control" disabled={mode === "View" ? true : false} placeholder="Example input" value={formInputs.price} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className="form-group">
                                <label className="inputLabel">Actual Price<span>*</span></label>
                                <input name="actual_price" type="text" className="form-control" disabled={mode === "View" ? true : false} placeholder="Example input" value={formInputs.actual_price} onChange={(e) => handleChange(e)} />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div className="form-group">
                                    <label className="inputLabel">Marketing Description<span>*</span></label>
                                    {formInputs?.marketing_description?.map((foot, index) => (
                                        <div style={{ display: "flex" }}>
                                            <input name="marketing_description" type="text" disabled={mode === "View" ? true : false} className="form-control" id="selectList1" placeholder="Example input" value={get_specialCharReplace(foot)} onChange={(e) => handleChange(e, index)} sx={{ marginTop: ' 2%' }} />

                                            {mode === "View" ? <></> :
                                                <IconButton onClick={(e) => handleDeleteDesc(index)}>
                                                    <DeleteOutlineOutlinedIcon />
                                                </IconButton>}
                                            {mode === "View" ? <></>
                                                :
                                                (index === formInputs?.marketing_description?.length - 1 && (
                                                    <IconButton onClick={(e) => handleAddDesc()}>
                                                        <AddIcon />
                                                    </IconButton>
                                                ))
                                            }
                                        </div>
                                    ))}

                                </div>
                            </div>

                            {mode === "View" ? <></> : <div className=" btn-comp1 " style={{ marginTop: "4%" }}>
                                <button type="button" className="btn submit-btn" onClick={() => modalSubmit()} >Submit</button>
                            </div>}

                        </div>

                    </form>
                </Modal.Body>
            </Modal>

        )
    }

    const headers = {
        Authorization: `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjE2MSwiaWF0IjoxNjY1MDU4ODEwNDM4LCJleHAiOjE2NjUwNTk2NzQ0Mzh9.gGqLzuKHNw23K_Ov-Y1AImas9V3OYe3m1PuBR0EaWUBDWurQESkc-MA_7sXud1MYeQe3ul3nJLV0baRrBzNDBzTdcaNb4SArSQC_zgaaWH7VzIaI95sPXHupSH6arLoKe8R6LYzWJ6FNOXlCBFeopEj_XUct0ilXUX6zW9S16-IJRFlBxrILED4XK0VZzOCsPtJJtzr2XyMUCyPcLAT0SnKshxWOgZ3322QUy7myuZWGk4lSEbz4bxEzrUKWHB5hCJ1VsjyUiQk9nxHf1VYmzXcjDTy7ckE0BrUU6PVLzIYwuRlpFSnRZSTA02pIi5_w9CaGAGRX6AP0PSgVdczrgIR5Cc_HN2Dyw1Nz0BBgiFxQja0oZ4v9HGcTXqQYzIy6nPssJ2AhoU4mmtgcqC4r2WlvNcwYZ5IlVzMMCugaGIRd-flQjRmn5Wx6Pd5kCtSYwotN4jEVl7pQxx1-vRye6F3YBFQCfyX0srQuJ2PfKq2CeG1mwmo-5yiNMwPrm__HwJ4uejZtEN5dTSNW5z53tUWhsp87KbdPITxuoqInsgOA1NFOOyLPXs7AGffZj8jdk8-QYIQ6kSmDlUEJ4vpljj3fYX_TwPKSHtg7NhTUs613nx8rEewZElPYe-0AwiNxcrYRIRNyUlUGOV7u2mHCyoYNfhua5mFl0XV1BG_s8cU`
    };


    const handlePostRequest = async () => {
        let data = {
            act_type_id: formInputs.act_type_id,
            name: formInputs.name,
            description: formInputs.description,
            duration: formInputs.duration,
            price: parseInt(formInputs.price),
            actual_price: parseInt(formInputs.actual_price),
            marketing_description: formInputs.marketing_description.map((bullet) => {
                return send_specialCharReplace(bullet)
            })
        }
        data.price = parseInt(data.price)
        console.log("Post data:", data);

        await serviceCM
            .post("/sku", data)
            .then((res) => {
                console.log("Response from backend is", JSON.stringify(res, null, 2));
                res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")

                let temp = JSON.parse(JSON.stringify(skuData));

                temp = ([{
                    id: res?.data?.id,
                    act_type_id: formInputs.act_type_id,
                    name: formInputs.name,
                    description: formInputs.description,
                    duration: formInputs.duration,
                    price: parseInt(formInputs.price),
                    actual_price: parseInt(formInputs.actual_price),
                    marketing_description: JSON.stringify({ why_this: formInputs.marketing_description }),
                }, ...temp])

                console.log("Post temp value:", temp)
                setSkuData(temp)
                data_manupulator_sku(temp, activityData)
            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                window.alert(err.response.data.message)
            });
    }

    const handlePutRequest = async () => {
        console.log("PUT Request")
        let body = {
            act_type_id: formInputs.act_type_id,
            name: formInputs.name,
            description: formInputs.description,
            duration: formInputs.duration,
            price: parseInt(formInputs.price),
            actual_price: parseInt(formInputs.actual_price),
            marketing_description: formInputs.marketing_description.map((bullet) => {
                return send_specialCharReplace(bullet)
            })
        }
        body.id = from_table?.id
        // delete body.image_url
        console.log(body)
        serviceCM
            .put("/sku", body)
            .then((res) => {

                console.log("Response from backend is", JSON.stringify(res, null, 2));
                res.data.success ? console.log("Succesfull Operation") : console.log("Failed Operation")

                let temp = JSON.parse(JSON.stringify(skuData));

                temp[from_table?.index].act_type_id = formInputs.act_type_id
                temp[from_table?.index].name = formInputs.name
                temp[from_table?.index].description = formInputs.description
                temp[from_table?.index].duration = formInputs.duration
                temp[from_table?.index].price = formInputs.price
                temp[from_table?.index].actual_price = formInputs.actual_price
                temp[from_table?.index].marketing_description = JSON.stringify({ why_this: formInputs.marketing_description })

                console.log("Put temp value:", temp)
                setSkuData(temp)
                data_manupulator_sku(temp, activityData)

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);
                window.alert(err.response.data.message)
            });
    }


    const handleDelete = async (jd_id, index) => {
        console.log("delete", jd_id)
        let list = [...rowsData];

        console.log("listtttt1", list);
        serviceCM
            .delete("/duration", {
                data: { "jd_id": jd_id }
            })
            .then((res) => {
                console.log("Response from backend is", res);
                res.success ? console.log("Succesfull Operation") : console.log("Failed Operation")
                list.splice(index, 1);
                setRowsData(list)
                console.log("listtttt2", list);

            })
            .catch((err) => {
                console.log("Errog Msg: ", err);

                console.log(err.response.data.message)
                window.alert(err.response.data.message)

            });
    }

    const fileDownload = () => {
        console.log("Downloading the file");
        console.log("Rows data now is:  ", rowsData);
        // Simulate a button click after 2 seconds
        const csvData = rowsData.map((row) => Object.values(row).join(",")).join("\n");
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, `${window.location.pathname}_${currentDate.getDate() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + "-" + currentDate.getHours() + ":" + currentDate.getMinutes() + ":" + currentDate.getSeconds()}.csv`);


    }



    return (<div>
        <RowsContext.Provider value={rowsData}>
            <TopBar />
            <SideNavigationBar />
            {loading ? <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: '0 auto' }}><Loader show={loading} /></div> : <>
                {console.log("from_table.index", from_table?.index)}
                {console.log({ formInputs })}
                {console.log({ rowsData })}
                {console.log({ skuData })}
                {console.log({ durationData })}
                <EnhancedTable mainFile_modal={Modal_SKU} data_getter={data_getter} fileDownload={fileDownload} headCells={headCells} count={count} path={window.location.pathname} />
            </>}
        </RowsContext.Provider>

    </div>
    )
}


export default SKU