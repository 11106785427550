import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Cookies from 'js-cookie';
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Color } from "../../../../GlobalStyles";
import Service from "../../../../services/httpService";
import SideNavigationBar from "../../../reusables/SideNavigationBar/SideNavigationBar";
import Loader from "../../../reusables/Loader/Loader";
function OfflineAndResumesFromDashboard() {
  const service = new Service();
  const location = useLocation();

  const navigate = useNavigate();
  //   const [selectedColleges, setSelectedCollege] = useState(null);
  const [allBatches, setAllBatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  const fromResumesDigitalPath = queryParams.get('fromResumesDigitalPath');
  const is_offline = queryParams.get('is_offline');

  useEffect(() => {
    const getBatchData = async () => {
      const headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
      };
      const res = await service.get(`/getAllResumeAndOfflineProcess?type=${fromResumesDigitalPath}&is_offline=${is_offline}`, { headers });
      console.log("allBatches", res.data);
      let temp_batch = res.data.map((item) => {
        return {
          ...item,
          is_loading: false
        };
      });
      console.log("temp_batch", temp_batch);

      setAllBatches(temp_batch);

    };

    getBatchData();
    setLoading(false)
  }, []);

  const handleResumeAnalyticsClick = async (index, process_id, is_loading, user_group_id, college_name, batch_name) => {
    try {
      setAllBatches(prevCardInfo => {
        const updatedBundleInfo = [...prevCardInfo];
        updatedBundleInfo[index] = {
          ...updatedBundleInfo[index],
          is_loading: !is_loading,
        };
        return updatedBundleInfo;
      });
      if (fromResumesDigitalPath === "RDS") {
        navigate("/ResumeTemplate?process_id=" + process_id, { state: { process_id: process_id , batch_id: user_group_id, college_name:college_name, batch_name:batch_name} })
      } else {
        navigate(`/OfflineSessions/StudentSummary?process_id=${process_id}`)
      }
      setAllBatches(prevCardInfo => {
        const updatedBundleInfo = [...prevCardInfo];
        updatedBundleInfo[index] = {
          ...updatedBundleInfo[index],
          is_loading: !is_loading,
        };
        return updatedBundleInfo;
      });
    } catch (err) {
      setAllBatches(prevCardInfo => {
        const updatedBundleInfo = [...prevCardInfo];
        updatedBundleInfo[index] = {
          ...updatedBundleInfo[index],
          is_loading: !is_loading,
        };
        return updatedBundleInfo;
      });
      window.alert(err.response.data.message)
    }
  }

  const goBack = () => {
    console.log("Back Button is Clicked Now");
    navigate(-1);
  };




  return (
    <div>
      <SideNavigationBar />
      <Box sx={{ padding: "5rem" }}>
        <div className="topHeadings" style={{ display: "flex", marginLeft: "3rem", marginBottom: "3rem" }}>
          <ArrowBackIcon className="Arrow-back-btn" style={{ height: "30px", width: "30px", marginRight: "0.5rem" }} onClick={() => goBack()} />
          <p style={{ color: Color.neutralMidGrey }}>{fromResumesDigitalPath === "RDS" ? "Resumes Digital" : "Offline Sessions"}</p>
        </div>
        {loading === false ? (
          <>
            <Grid container columnGap={4} rowGap={6} sx={{ display: "flex", justifyContent: "left", marginLeft: "5rem" }}>
              {allBatches.map((college, index) => (
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    style={{
                      boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.1)",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      cursor: "pointer",
                      transition: "box-shadow 0.3s",
                      borderRadius: "1rem"
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.boxShadow = "0px 5px 20px rgba(0, 0, 0, 0.5)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.boxShadow = "0px 5px 15px rgba(0, 0, 0, 0.35)";
                    }}
                  // onClick={() => setSelectedCollege(college)}
                  >
                    <CardContent>
                      <Typography variant="h5" sx={{ fontSize: "20px" }}>
                        {college?.college_name}
                      </Typography>
                      <div className="container" style={{ margin: ".2rem 0" }}>
                        <Typography variant="body2" color={Color.rdcolor} class="providingSpaceForSubDetails" style={{ marginLeft: "0rem" }}>
                          Batch :
                        </Typography>
                        <Typography variant="body2" class="providingSpaceForSubDetails" >
                          {college?.group_name}
                        </Typography>
                      </div>
                      <div style={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: 'flex-end', gap: "10px", marginTop: ".5rem" }}>
                        <button
                          style={{
                            padding: "12px",
                            border: "none",
                            background: fromResumesDigitalPath === "RDS" ?  Color.rdcolor : Color.primary1,
                            borderRadius: "10px",
                            color: "#fff",
                            width: "100%",
                            fontSize: "12px",
                          }}
                          onClick={() =>
                            handleResumeAnalyticsClick(
                              index,
                              college?.process_id,
                              college?.is_loading,
                              college?.user_group_id,
                              college?.college_name,
                              college?.group_name
                            )
                          }
                        >
                          {college?.is_loading ? <CircularProgress sx={{ color: "#fff" }} size={20} /> : fromResumesDigitalPath === "RDS" ? "Settings" : "Go to Activity"}
                        </button>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <>
            <div style={{ position: "absolute", justifyContent: "center", alignItems: "center", margin: "0 auto", marginLeft: "45%", marginTop: "10%" }}>
              <Loader show={loading} />
            </div>
          </>
        )}
      </Box>
    </div>
  );
}

export default OfflineAndResumesFromDashboard;
